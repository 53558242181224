<template>
    <div v-if="!isMaintenanceMode" class="bg-[#d13724] text-white text-center p-2 w-full">
        <div class="flex items-center justify-center gap-6">
            <IconInfoCircle class="hidden lg:flex w-12" stroke="{2}" />
            <p class="font">
                Informujemy, że w piątek 21 lutego 2025r. nastąpi przerwa techniczna w godzinach 11:00-16:00. Za wszelkie niedogodności przepraszamy.
            </p>
        </div>
    </div>
</template>

<script setup>
    import { IconInfoCircle } from '@tabler/icons-vue'

    import { ref, onMounted } from 'vue'
    import axios from 'axios'

    const isMaintenanceMode = ref(false)

    const checkMaintenanceMode = async () => {
        try {
            const response = await axios.get(`${process.env.VUE_APP_API}api/settings/is-maintenance-mode`)

            isMaintenanceMode.value = response.data
        } catch (error) {
            console.error('Error checking maintenance mode:', error)
        }
    }

    onMounted(() => {
        checkMaintenanceMode()
        // Check every 5 minutes
        setInterval(checkMaintenanceMode, 5 * 60 * 1000)
    })
</script>

<style lang="scss" scoped></style>
