<template>
    <div v-if="isLoading" class="user-status"></div>
    <div v-else-if="computedStatus" class="user-status">
        <div>
            <IconUserPentagon stroke="1" width="32px" class="tabler-icon" />
        </div>
        <div class="status-content">
            <div>
                <p>
                    Status:
                    <span>{{ computedStatus }}</span>
                </p>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, computed, onMounted } from 'vue'
    import { IconUserPentagon } from '@tabler/icons-vue'
    import { storeToRefs } from 'pinia'
    import { useUserStore } from '@/stores/user'
    import { useMatchStore } from '@/stores/match'
    import { useTemporaryMatchStore } from '@/stores/temporaryMatch'

    const props = defineProps({
        status: {
            type: String,
            default: 'default',
        },
    })

    // State management
    const isLoading = ref(true)
    const dataInitialized = ref(false)

    // Store initialization
    const userStore = useUserStore()
    const matchStore = useMatchStore()
    const temporaryMatchStore = useTemporaryMatchStore()

    // Store refs
    const { user } = storeToRefs(userStore)
    const { match } = storeToRefs(matchStore)
    const { usersHasDifferentPayments, temporaryMatch } = storeToRefs(temporaryMatchStore)

    // Computed properties
    const hasUserStatusInMatch = computed(() => user.value?.status === 'in-match')
    const hasUserPlus = computed(() => user.value?.paymentPlus)
    const hasMatch = computed(() => match.value)

    const statusMap = {
        'temporary-disabled': 'Konto tymaczasowo zablokowane',
        'waiting-for-questionnaire': 'Oczekiwanie na kwestionariusz',
        'questionnaire-in-verification': 'Weryfikacja kwestionariusza',
        'waiting-for-match': 'Oczekiwane na dopasowanie',
        'waiting-for-response': 'Oczekiwanie na odpowiedź drugiej strony',
        'questionnare-delivered': 'Kwestionariusz dostarczony',
        'in-temporary-match': 'Aktywne dopasowanie',
        'in-match': 'Połączony z innym użytkownikiem',
        inactive: 'Konto nieaktywne',
    }

    const computedStatus = computed(() => {
        if (!dataInitialized.value) return ''

        if (usersHasDifferentPayments.value === true && !hasUserPlus.value && temporaryMatch.value && !hasMatch.value) {
            return 'Profil przedstawiony użytkownikowi z pakietem "Plus"'
        }

        if (usersHasDifferentPayments.value === true && !hasUserPlus.value && hasMatch.value && hasUserStatusInMatch.value) {
            return 'Aktywne dopasowanie z użytkownikiem z pakietem "Plus"'
        }

        return statusMap[props.status] || ''
    })

    // Data fetching
    const initializeData = async () => {
        isLoading.value = true
        try {
            await Promise.all([userStore.getUser(), temporaryMatchStore.getTemporaryMatch(), matchStore.checkMatches()])
            dataInitialized.value = true
        } catch (error) {
            console.error('Failed to initialize data:', error)
        } finally {
            isLoading.value = false
        }
    }

    // Lifecycle hooks
    onMounted(() => {
        initializeData()
    })
</script>

<style lang="scss" scoped>
    .user-status {
        display: flex;
        gap: 15px;
        align-items: center;

        p {
            font-size: 1.6rem;
            line-height: 3rem;
            color: #000000;
            font-weight: 700;
            text-transform: none;
            font-family: 'Open Sans', sans-serif;
            margin: 0;
        }
    }

    .tabler-icon {
        stroke: #d13724;
    }

    .animate-pulse {
        animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    }

    @keyframes pulse {
        0%,
        100% {
            opacity: 1;
        }
        50% {
            opacity: 0.5;
        }
    }
</style>
