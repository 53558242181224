<template>
    <div class="w-full mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
        <div class="py-6 px-10 flex items-center gap-8 border-b">
            <img
                :src="user?.photo1 || user?.photo2 || user?.photo3 || user?.photo4 || avatarImg"
                role="presentation"
                class="object-cover aspect-square w-24 h-24 rounded-full"
            />

            <div class="flex w-full items-center justify-between">
                <div class="flex flex-col">
                    <div class="flex flex-col lg:flex-row items-center gap-4">
                        <h2 class="text-3xl font-bold">
                            {{ user.email }}
                        </h2>
                        <p class="opacity-60 m-0 p-0 text-xl">{{ user?._id }}</p>
                    </div>

                    <div>
                        <span class="text-lg opacity-60" v-if="user?.status">
                            {{ formatStatus(user.status) }}
                        </span>
                    </div>
                </div>

                <!-- one global link to user profile  -->
                <router-link
                    :to="`/admin/users/user/${user._id}`"
                    class="bg-[#d13724] text-white uppercase font-semibold py-2 px-4 cursor-pointer hover:opacity-80"
                >
                    Zobacz profil
                </router-link>
            </div>
        </div>
        <div v-if="user?.isVerifiedByAdmin || user?.paymentBasic" class="p-4 space-y-4">
            <div class="flex items-center gap-2">
                <div v-if="user?.isVerifiedByAdmin" class="px-4 py-2 rounded-full text-lg font-medium bg-gray-200">Zweryfikowany</div>
                <div v-if="user?.paymentBasic" class="px-4 py-2 rounded-full text-lg font-medium bg-gray-200">
                    Pakiet Basic ważny do
                    {{ formatDate(user.paymentBasicExpirationDate) }}
                </div>
                <div v-if="user?.paymentPlus" class="px-4 py-2 rounded-full text-lg font-medium bg-gray-200">
                    Pakiet Plus ważny do
                    {{ formatDate(user.paymentPlusExpirationDate) }}
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    const avatarImg = require('@/assets/images/ludzik1.png')

    const statusMap = {
        'temporary-disabled': 'Konto tymaczasowo zablokowane',
        'waiting-for-questionnaire': 'Oczekiwanie na kwestionariusz',
        'questionnaire-in-verification': 'Weryfikacja kwestionariusza',
        'waiting-for-match': 'Oczekiwane na dopasowanie',
        'waiting-for-response': 'Oczekiwanie na odpowiedź drugiej strony',
        'questionnare-delivered': 'Kwestionariusz dostarczony',
        'in-temporary-match': 'Aktywne dopasowanie',
        'in-match': 'Połączony z innym użytkownikiem',
    }
    const formatStatus = status => {
        return statusMap[status]
    }

    const props = defineProps({
        user: Object,
    })

    const formatDate = date => {
        if (!date) return 'N/A'
        return new Date(date).toLocaleDateString('pl-PL', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        })
    }
</script>

<style lang="scss" scoped></style>
